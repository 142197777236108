$black-coffee: #32292fff;
$peach-crayola: #ffc09fff;
$glaucous: #6184d8ff;
$magic-mint: #afe0ceff;
$dartmouth-green: #306b34ff;
$red-salsa: #fb6b70;
$teal: #63fed8;
$lime: #51D395;
$green: #8EFD7D;
$green-text: #22a064;

// new colors
$dark-purple: #2e0219ff;
$black-coral: #465362ff;
$fire-engine-red: #EF3340;
$platinum: #dce0d9ff;
$baby-powder: #f7f9f7ff;

$pop: #E2F3EE;
$bg: #fff;
$text: $baby-powder;
$text-dark: #000;
// $cell-bg: $dartmouth-green;
$dem: $glaucous;
$gop: $red-salsa;

$gap: 30px;

$phone-width: 768px;

$btn-color: $green;